.rtable-main-container{
    height: 100% !important;
    width : 100% !important;
border-radius: 6px;
box-shadow: none !important;
justify-content: flex-start;
display: flex;
flex-direction: row; 
}
.rtable-main{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}



.rtable-table{
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 30px;
  height: calc(100% - 80px) !important;
  //width: 50% !important;
  overflow-y: auto !important; /* Allow vertical scrolling */ 
-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.rtable-main-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }
.top-controls{
  height: 40px !important;
  display: flex;
  width: 100% !important;
  position: absolute;
  top: 40px;
  left: 10px;
  z-index: 99;
  background-color: white !important;
  flex-direction: row;
  justify-content: flex-start;
}

  .rtable-control-checkbox-1{
    position:absolute;
    width: 180px;
    height: 40px;
    top: 6px;
    right: 160px;
    z-index: 100 !important;
    opacity: 1 !important;
  }
  .rtable-control-checkbox-2{
    width: 140px !important;
    height: 100% !important;
  }

  .rtable-text-1{
    font-size: 12px;
  } 

  .rtable-icon-1{
    width: 16px !important;
    height: 16px !important;
    color: green
  }

  .rtable-icon-2{
    width: 16px !important;
    height: 16px !important;
    color: rgb(207, 142, 0);
  }

  .rtable-icon-3{
    width: 100% !important;
    height: 100% !important;
    color: #E24444;
    border: 2px solid #E24444;
    border-radius: 5px;
  }

  .rtable-x-iconbtn{
    border-radius: 4px !important;
    width: 30px !important;
    height: 30px !important;
    background-color: rgb(255, 255, 255) !important;
  border: 1px solid #E24444;
  padding: 0px !important;
  }


  .rtable-button-yes{
    margin: 0px !important;
    width: 30px !important;
    height: 16px !important;
    background-color: #E24444 !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    border: none !important;
  }

  .rtable-button-no{
    margin: 0px !important;
    width: 30px !important;
    height: 16px !important;
    background-color: var(--purple) !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    border: none !important;
  }

  .rtable-btn-text{
    width: fit-content !important;
    height: fit-content !important;
    font-size: 0.7em !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .rtable-button-container{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    
  }

  .rtable-delete-confirmation-container{
    width: 100% !important;
    font-size: 0.9em;
    padding: 0px !important;
    margin: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;

  }



  .rtable-create-button{
    margin-right: 40px !important;
    height: 100% !important;
    width: 140px !important;
    background-color: var(--purple) !important;
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    border: none !important;
  }


  .rtable-create-button-bottom-text{
    margin-top: 2px !important;
    font-size: 14px !important;
    color: white !important;
    text-transform: none !important;
}






@keyframes load {
  0%{
      opacity: 0.08;
/*         font-size: 10px; */
/* 				font-weight: 400; */
      filter: blur(5px);
      letter-spacing: 3px;
      }
  100%{
/*         opacity: 1; */
/*         font-size: 12px; */
/* 				font-weight:600; */
/* 				filter: blur(0); */
      }
}

.animate {
display:flex;
justify-content: center;
align-items: center;
height:100% !important;
margin: 0px !important;
/* 	width: 350px; */
/* 	font-size:26px; */
font-family: 'Segoe UI', sans-serif;
animation: load 1.2s infinite 0s ease-in-out;
animation-direction: alternate;
text-shadow: 0 0 1px var(--white-color);
}



.MuiTableContainer-root{
  height: 100% !important;
}








