/* App.css */
@import './styles/customAuthStyles.css';


.routeCont{
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.vlogo{
position: absolute;
  top: 20px;
  left: 20px;
width: 70px !important;
height: 30px !important;
  opacity: 1 !important;
  z-index:99;
}
.p4ulogo{
position: absolute;
  top: 10px;
  left: 120px;
height: 45px !important;
  opacity: 1 !important;
   z-index:99;
}


.main{
  min-height: 100% !important;
  width: 100% !important;
  margin: 0px !important;
}

 