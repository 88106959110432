/* src/components/ModelViewer.css */
.canvas-container {
    height: 100% !important;
    width: 100% !important;
 
  }

  .btn-center-axis{
    position: absolute;
    top: 90px !important;
    left: 20px !important;
    z-index: 1000 !important;
    border-radius: 5px;
    color: white;
    background-color: var(--darkblue-color) !important;
    width: 140px !important;
    height: 60px;
    box-shadow: none !important;
    border: none !important;
  }


  .dexx{
    background-color: white !important;
    height: 100% !important;
  }


  .mapTitle {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    color: white !important;
  }
  .mapSubTitle {
    position: absolute;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
    color: white !important;
  }
  
  .backBtn{
    position: absolute !important;
    top: 100px;
    left: 20px;
    z-index: 99;
    
    height: 40px;
  }

  .backBtnIcon{
    color: white !important
  }

  .backBtnLabel{
    color: white !important;
  }