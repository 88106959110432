.fdc-main-container{
    height: 80vh !important;
    width : 80vw !important;
    min-width : calc(680px + 220px) !important;
    min-height: 560px !important;
 display: flex;
 flex-direction: column;
 justify-content: space-evenly;
 align-items: center;
 background-color: white;
 border-radius: 14px; 
} 

.fdc-container-wrapper-1, .fdc-container-wrapper-2{
   
    height: calc(50% - 15px) !important;
    width : calc(100% - 20px) !important;
    min-width : 300px !important;
 display: flex;
 flex-direction: row;
 justify-content: space-evenly;
 align-items: center;
}

.fdc-container-1{
    max-width: 220px !important;
    min-width: 220px !important;
    height: 100%;
    box-shadow: 0px 0px 16px 4px var(--white-color);
    background-color: white;
border-radius: 10px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
padding: 10px;
overflow-y: scroll;
}

.fdc-container-2{
    max-width: calc(100% - 230px) !important;
    min-width:  calc(100% - 230px) !important;
    height: 100%;
border-radius: 10px;
margin-left: 10px;
box-shadow: 0px 0px 16px 4px var(--white-color);
background-color: white;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
padding: 10px;
}


.fdc-container-3{
    max-width: 220px !important;
    min-width: 220px !important;
    height: 100%;
    box-shadow: 0px 0px 16px 4px var(--white-color);
    background-color:white;
border-radius: 10px;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
padding: 10px;
overflow-y: scroll;
}



.fdc-container-4{
    position: relative;
    max-width: calc(100% - 230px) !important;
    min-width:  calc(100% - 230px) !important;
    flex-grow: 1;
    height: 100%;
border-radius: 10px;
margin-left: 10px;
box-shadow: 0px 0px 16px 4px var(--white-color);
background-color: white;
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
padding: 10px; 
}

.fdc-container-main-title{
    color: var(--bluetext);
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 10px;
}
.fdc-container-title{
    color: rgba(0, 0, 0, 0.7);
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 20px;
}
.fdc-container-title-value{
    color: rgba(0, 0, 0, 0.7);
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 0px;
}

.fdc-container-3-button{
    width: calc(100% - 20px) !important;
    height: calc(50% - 10px) !important;
    background-color: var(--darkblue-color) !important
}

.fdc-container-3-button-badge-red{
    position: absolute !important;
    bottom: 5px !important;
    right: 10px !important;
}
.fdc-container-3-button-badge{
  
    position: absolute !important;
    bottom: 0px !important;
    right: 10px !important;
}
.fdc-container-3-button-badge-red{
    position: absolute !important;
    bottom: 26px !important;
    right: -6px !important;
    border : 1px solid red;
    border-radius: 30px;
    width: fit-content;
    height: fit-content;
    background-color:red;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px; 
}
.fdc-container-3-button-badge-green{
    position: absolute !important;
    bottom: 26px !important;
    right: -6px !important;
    border : 1px solid rgb(23, 190, 51);
    border-radius: 30px;
    width: fit-content;
    height: fit-content;
    background-color:rgb(23, 190, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px; 
}

.MuiBadge-badge{
    width: 10px !important;
    height: 10px !important;
    background-color: rgb(231, 60, 60) !important;
    font-size: 10px;
    top: 20px;
    right:5px;
    padding: 0px;
}

.fdc-container-3-button-icon{
    width: 26px !important;
    height: 26px !important;
    background-color: var(--darkblue-color) !important;
}

.fdc-container-3-button-tooltip-icon{
    width: 16px !important;
    height: 16px !important;
    background-color: var(--white-color) !important;
}


.fdc-container-2-top-wrapper, .fdc-container-4-top-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}

.fdc-container-2-bottom-wrapper, .fdc-container-4-bottom-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px);
}
.fdc-container-4-button{
    width: 80px !important;
    margin-left: 10px !important;
    height: 100% !important;
    background-color: var(--darkblue-color) !important;
}

.fdc-container-4-bottom-text{
    font-size: 14px !important;
    color: var(--white-color) !important;
    text-transform: none !important;
}


.chip-style{
    font-size: 0.6em !important;
    line-height: 100%;
    height : 16px !important;
    color: black !important;
}

.chip-first{
    margin-right: 10px;
}

.chip-csm2{
    background-color: rgb(208, 224, 112) !important;
}

.chip-l42{
    background-color: rgb(90, 162, 221) !important;
}

.chip-tmp2{
    background-color: rgb(221, 90, 90) !important;
}



.fdc-copy-icon{
    width : 14px !important;
    height: 14px !important;
}


.fdc-container-1-button{
    width: 20px !important;
    height: 20px !important;
    margin-left: 6px !important;
}


.fdc-chip-container{
    margin-bottom: 16px !important;
    width: 100% !important;
    height: 20px !important;
}

.fdc-jobcard{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid grey;
    border-radius: 0px;
    background-color: white !important;
    color: rgb(0, 0, 0);
}
.fdc-jobcard-bot{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.fdc-jobcard-top{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.fdc-jobcard-textb{
    font-weight: bold;
    margin-right: 10px;
    font-size: 12px !important; 
    color: var(--bluetext);   
}

/* PULSE BUBBLES */

.pulse-container {
    width: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pulse-bubble {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--bluetext);
  }
  
  .pulse-bubble-1 {
      animation: pulse .4s ease 0s infinite alternate;
  }
  .pulse-bubble-2 {
      animation: pulse .4s ease .2s infinite alternate;
  }
  .pulse-bubble-3 {
      animation: pulse .4s ease .4s infinite alternate;
  }

  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .25;
      transform: scale(.75);
    }
  }


/* Custom scrollbar styles */
*::-webkit-scrollbar {
    width: 8px !important;
  }
  
  *::-webkit-scrollbar-track {
    background-color: transparent !important;  /* Scrollbar track color */
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgb(150, 149, 149) !important; /* Scrollbar thumb color */
    border-radius: 4px !important;
  }
  
  * {
    scrollbar-color: transparent transparent !important; /* For Firefox */
    scrollbar-width: thin !important; /* For Firefox */
  }





















.fdc-progress-bar{
    width: 300px !important;
    height: 20px !important;
    border-radius: 4px !important;
    background-color: #c5fab1 !important;
}

.fdc-container-3-progress-container{
    width: 100%;
    height: 100%;

}







