.main-map-cont{
    height: 100%;
    width:100%;
}




 
div.leaflet-popup-content-wrapper{ 
    height: fit-content !important;
    box-shadow: none !important; 
    margin-bottom: -10px !important;
    width: 100% !important;
    background-color: rgba(241, 240, 240,0); 
}


div.leaflet-popup-content{ 
    width: 100% !important;
    height: 100% !important;
    box-shadow: none !important; 
    margin: 0 !important;  
}


#root > div > div > div.routeCont > div > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-popup-pane > div > div.leaflet-popup-tip-container > div{
    background: var(--valeo-color) !important;
 box-shadow: none !important;
 opacity:0 !important;
}


#root > div > div > div.routeCont > div > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-popup-pane > div > a{
    display: none;
}





.two-d-map-search-bar{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000 !important;

}


.leaflet-control-attribution {
    display: none !important;
}






