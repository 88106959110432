.header-container{
    width: 100%;
    height: 70px !important;
    position: absolute;
    top:0;
    left:0;
    z-index: 10 !important;
}

 

.accnt-icn{
    color: white;
    font-size: 1.2em !important;
}

.vlogo{
    height: 30px !important;
    width: 65px !important;
    opacity: 1 !important;
} 

.header-appbarMenu{
  flex-grow: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.header-appbarMenu-item{
 margin-left: 30px;
}

.toolb{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.appbar {
    background-color: rgba(0, 0, 0, 0) !important;
    color: black !important;
    
    
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3) !important;
  }

  

 

  #menu-appbar > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-1xomo8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
    //background-color: black !important;
    color:var(--white-color) !important;
  background-color: rgb(58, 71, 100);
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    
  }

  #menu-appbar > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-1xomo8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper > ul  {
    color: rgba(0, 0, 0, 0.7) !important;
 
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0) !important;
    border: 0px solid rgba(255, 255, 255, 0) !important;
  }

  #menu-appbar > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-1xomo8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper > ul > li {
    color:var(--white-color) !important;
    background: rgba(255, 255, 255, 0) !important;
   box-shadow: 0 4px 6px rgba(0, 0, 0, 0) !important;
    border: 0px solid rgba(255, 255, 255, 0) !important;
  }




  #menu-appbar > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-1xomo8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper > ul > li.MuiButtonBase-root.MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters.Mui-disabled.MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters.appbarMenu.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root{
    color:rgb(12, 12, 12) !important;
    opacity: 1;
    
  }


