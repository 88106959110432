body {
  margin: 0 !important ;
  font-family: 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Segoe UI', sans-serif;
}


/* index.css */

body, html, #root {
  margin: 0px !important;
  padding: 0px !important;
  min-height: 100vh !important;
  height: auto !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  

/* UI Properties */
background: transparent radial-gradient(closest-side at 50% 50%, #354E6E 0%, #363E4B 100%) 0% 0% no-repeat padding-box;
opacity: 1;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  width: 100% !important;
}




