.mtable-main-container{
    height: 100% !important;
    width : 100% !important;
border-radius: 6px;
overflow-y: auto; /* Allow vertical scrolling */ 
-ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
scrollbar-width: none; /* Hide scrollbar for Firefox */
box-shadow: none !important;
display: flex;
}

 

.mtable-main-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
  }



  .mtable-icon-1{
    width: 16px !important;
    height: 16px !important;
    color: green
  }

  .mtable-icon-2{
    width: 100% !important;
    height: 100% !important;
    color: #E24444;
    border: 2px solid #E24444;
    border-radius: 5px;
  }

  .mtable-icon-3{
    color: white !important;
    width: 20px !important;
    height: 20px !important;
  }

/*.mtable-modal-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;
  border: 0px solid #000;
  box-shadow: 24px;
  padding: 14px;
  border-radius: 10px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.mtable-modal-buttons{
  padding: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mtable-close-btn{
  width: 100px !important;
  height: 30px !important;
  color: rgb(255, 0, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid red !important;
  border-radius: 10px;
}

.mtable-confirm-btn{
  width: 100px !important;
  height: 30px !important;
  color: rgb(7, 214, 0) !important;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(7, 214, 0) !important;
  border-radius: 10px;
}

.mtable-modal-description-red{
  color: red
}

.mtable-modal-description{
font-size: 13px;;
}

.mtable-modal-title{
  color: rgb(58, 71, 100);
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 16px
}*/

.mtable-3d-iconbtn{
  border-radius: 4px !important;
  width: 30px !important;
  height: 30px !important;
  background-color: var(--purple) !important;
}

.mtable-x-iconbtn{
  border-radius: 4px !important;
  width: 30px !important;
  height: 30px !important;
  background-color: rgb(255, 255, 255) !important;
border: 1px solid #E24444;
padding: 0px !important;
}