:root {
    --valeo-color: #82E600;
    --darkblue-color: rgb(58, 71, 100);
    --white-color:#e6e5e5;
    --grey1:#00000029;
    --grey2:#707070;
    --grey3:#4D576A;
    --grey4:#363E4B;
    --greyblue:#354E6E;
    --purple:#8C8DF9;
    --beige:#E3BE97;
    --greytext:#C8CBDA;
    --bluetext:#6C7EA5;
  }