.uploadpage-main-container {
  height: calc(100vh - 65px);
  width : 100vw;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Segoe UI', sans-serif;
  font-size: 2rem;
}

.uploadpage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
padding: 10px;
border-radius: 14px; 
background-color: white !important;
}

.uploadpage-table-container{
  box-shadow: 0px 0px 16px 4px var(--white-color);
  padding: 10px;
  border-radius: 10px;
display: flex;
flex-direction: column;
background-color: white !important;
}

.red-text{
  color: red !important;
}


.uploadpage-select-btn{
  margin-right: 10px !important;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 30px !important;
  width: 140px !important;
  border: 1px solid var(--purple) !important;
  align-items: center;
  border-radius: 6px !important;
  background-color:   none !important;
  color: var(--purple) !important;
  text-transform:none !important;
 // border: 1px solid white !important;
}

.uploadpage-upload-btn{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  height: 30px !important;
  width: 140px !important;
 // border: 1px solid rgb(255, 255, 255) !important;
  align-items: center;
  border-radius: 6px !important;
  background-color:   var(--purple) !important;
  color: var(--white-color) !important;
  text-transform:none !important;
 // border: 1px solid white !important;
}


.uploadpage-loader-container{
  position: relative;
}

.uploadpage-upload-btn{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
 // border: 1px solid rgb(255, 255, 255) !important;
  align-items: center;
 
  box-shadow: none;
  color: white !important;
  padding: 2px !important;

}

.uploadpage-upload-icn{
  color: var(--white-color) !important;
  font-size: 2em !important;
  height: 50px !important;
  width: 50px !important;
}

.disabled-btn{
  cursor: not-allowed !important;
}









.selectfiles{
  margin-top: 2px !important;
}

.uploadfiles{
  margin-top: -14px !important;
}

.uploadpage-mid-container{
  width: 100%;
  height: 80%;
  border: 1px solid green
}


.uploadpage-text-m{
font-size: 14px;
color: var(--white-color);
}

.uploadpage-text-s{
  font-size: 12px;
  color: var(--white-color);
}



.uploadpage-btn-container{
  width: 40%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}


.table-cell-invalid-msg{
  color: red !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}
.table-cell-valid-msg{
  color: black !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.table-cell-valid-icn{
color: green !important;
}
.table-cell-invalid-icn{
  color: red !important;
}



.uploadpage-table{
  color: var(--white-color) !important;
}



.uploadpage-title{
  color: var(--bluetext);
  font-weight: 500;
  font-size: 17px;
}

