.main-container{
    height: calc(100vh - 65px) !important;
    width : 100vw;
    margin-top: 65px !important;
    background-color: white !important;
}

.tdp-back-button{
    position:absolute;
    z-index: 1000 !important;
    top: 10;
    left: 10;
}